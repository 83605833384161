import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const InfoBlock = makeShortcode("InfoBlock");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "our-pledge",
      "style": {
        "position": "relative"
      }
    }}>{`Our Pledge`}<a parentName="h1" {...{
        "href": "#our-pledge",
        "aria-label": "our pledge permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We as members, contributors, and stewards pledge to make participation in our community a harassment-free experience for everyone, regardless of age, body size, visible or invisible disability, ethnicity, sex characteristics, gender identity and expression, level of experience, education, socio-economic status, nationality, personal appearance, race, caste, color, religion, or sexual identity and orientation.`}</p>
    <p>{`We pledge to act and interact in ways that contribute to an open, welcoming, diverse, inclusive, and healthy community.`}</p>
    <h2 {...{
      "id": "our-standards",
      "style": {
        "position": "relative"
      }
    }}>{`Our Standards`}<a parentName="h2" {...{
        "href": "#our-standards",
        "aria-label": "our standards permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Examples of behavior that contributes to a positive environment for our community include:`}</p>
    <Process mdxType="Process">
      <p>{`Demonstrating empathy and kindness toward other people `}</p>
      <p>{`Being respectful of differing opinions, viewpoints, and experiences  `}</p>
      <p>{`Giving and gracefully accepting constructive feedback  `}</p>
      <p>{`Accepting responsibility and apologizing to those affected by our mistakes, and learning from the experience  `}</p>
      <p>{`Focusing on what is best not just for us as individuals, but for the overall community`}</p>
    </Process>
    <p>{`Examples of unacceptable behavior include:`}</p>
    <Process mdxType="Process">
      <p>{`The use of sexualized language or imagery, and sexual attention or advances of any kind`}</p>
      <p>{`Trolling, insulting or derogatory comments, and personal or political attacks`}</p>
      <p>{`Public or private harassment`}</p>
      <p>{`Publishing others’ private information, such as a physical or email address, without their explicit permission`}</p>
      <p>{`Other conduct which could reasonably be considered inappropriate in a professional setting`}</p>
    </Process>
    <h2 {...{
      "id": "minors",
      "style": {
        "position": "relative"
      }
    }}>{`Minors`}<a parentName="h2" {...{
        "href": "#minors",
        "aria-label": "minors permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`If you are under the age of 18, you must obtain consent from your parent or guardian to participate in our events and, as such, your parent or guardian is required to agree to these conditions. An agreement signed by your parent or guardian must be submitted separately before attending any Kernel Event. You acknowledge that, even as a minor, you must read and understand this code of conduct, and that you have the capacity to do this and intend to abide by the standards and requirements described here.`}</p>
    <h1 {...{
      "id": "who-is-responsibile",
      "style": {
        "position": "relative"
      }
    }}>{`Who Is Responsibile?`}<a parentName="h1" {...{
        "href": "#who-is-responsibile",
        "aria-label": "who is responsibile permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Stewards are responsible for clarifying and enforcing our standards of acceptable behavior and will take appropriate and fair corrective action in response to any behavior that they deem inappropriate, threatening, offensive, or harmful.`}</p>
    <p>{`Stewards have the right and responsibility to remove, edit, or reject comments, commits, code, wiki edits, issues, and other contributions that are not aligned to this Code of Conduct, and will communicate reasons for moderation decisions when appropriate.`}</p>
    <h2 {...{
      "id": "scope",
      "style": {
        "position": "relative"
      }
    }}>{`Scope`}<a parentName="h2" {...{
        "href": "#scope",
        "aria-label": "scope permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This Code of Conduct applies within all community spaces, and also applies when an individual is officially representing the community in public spaces. Examples of representing our community include using an official e-mail address, posting via an official social media account, or acting as an appointed representative at an online or offline event.`}</p>
    <p>{`Instances of abusive, harassing, or otherwise unacceptable behavior may be reported to the stewards at `}<a parentName="p" {...{
        "href": "mailto:moderation@kernel.community"
      }}>{`moderation@kernel.community`}</a>{` `}</p>
    <p>{`All complaints will be reviewed and investigated promptly and fairly.`}</p>
    <p>{`All stewards are obligated to respect the privacy and security of the reporter of any incident.`}</p>
    <h2 {...{
      "id": "community-impact-guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Community Impact Guidelines`}<a parentName="h2" {...{
        "href": "#community-impact-guidelines",
        "aria-label": "community impact guidelines permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Stewards will follow these Community Impact Guidelines in determining the consequences for any action they deem in violation of this Code of Conduct:`}</p>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Correction`}</strong></p>
        <p>{`Community Impact: Use of inappropriate language or other behavior deemed unprofessional or unwelcome in the community.`}</p>
        <p>{`Consequence: A private, written warning from community stewards, providing clarity around the nature of the violation and an explanation of why the behavior was inappropriate. A public apology may be requested.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Warning`}</strong></p>
        <p>{`Community Impact: A violation through a single incident or series of actions.`}</p>
        <p>{`Consequence: A warning with consequences for continued behavior. No interaction with the people involved, including unsolicited interaction with those enforcing the Code of Conduct, for a specified period of time. This includes avoiding interactions in community spaces as well as external channels like social media. Violating these terms may lead to a temporary or permanent ban.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Temporary Ban`}</strong></p>
        <p>{`Community Impact: A serious violation of community standards, including sustained inappropriate behavior.`}</p>
        <p>{`Consequence: A temporary ban from any sort of interaction or public communication with the community for a specified period of time. No public or private interaction with the people involved, including unsolicited interaction with those enforcing the Code of Conduct, is allowed during this period. Violating these terms may lead to a permanent ban.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Permanent Ban`}</strong></p>
        <p>{`Community Impact: Demonstrating a pattern of violation of community standards, including sustained inappropriate behavior, harassment of an individual, or aggression toward or disparagement of classes of individuals.`}</p>
        <p>{`Consequence: A permanent ban from any sort of public interaction within the community.`}</p>
      </Box>
    </Process>
    <h1 {...{
      "id": "kernel-care",
      "style": {
        "position": "relative"
      }
    }}>{`Kernel Care`}<a parentName="h1" {...{
        "href": "#kernel-care",
        "aria-label": "kernel care permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <p>{`Caring harmoniously,`}<br parentName="p"></br>{`
`}{`we create together`}<br parentName="p"></br>{`
`}{`trusted space,`}<br parentName="p"></br>{`
`}{`open - joyfully! - for play.  `}</p>
    </InfoBlock>
    <p>{`Kernel is built on our responsibility to care for one another. Care is primary to all we do: we serve others first; listen with attention, courtesy and respect; speak care-fully; and share or respond in ways which exhibit love and consideration.`}</p>
    <p>{`As a Kernel Fellow or alum, `}<strong parentName="p">{`you have an obligation to care`}</strong>{`.`}</p>
    <p>{`In many modern constitutions, there are such obligations: South Africa (for instance) obliges her citizens to care for the elderly and the incarcerated. This is quite different from the enforcement of rights, and the question of how such obligations are fulfilled is difficult to describe in static text. One potential answer is “inspiration”. When our leaders act and live in a way which exemplifies the fulfillment of our collective obligations, then ordinary citizens are encouraged to do the same.`}</p>
    <p>{`We must each strive to be aware of the letters agreed upon here, `}<strong parentName="p">{`and`}</strong>{` alive to the spirit which informs them. We honour our individual responsibilities because it is a good way to live. We do so as a means of cultivating ongoing, wholesome relationships with others because we can only live fully when we live well together.`}</p>
    <h1 {...{
      "id": "attribution",
      "style": {
        "position": "relative"
      }
    }}>{`Attribution`}<a parentName="h1" {...{
        "href": "#attribution",
        "aria-label": "attribution permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`This Code of Conduct was first posted on our website on June 9th, 2022.`}</p>
    <p>{`This Code of Conduct is adapted from the Contributor Covenant, version 2.1.`}</p>
    <p>{`The Community Impact Guidelines were inspired by Mozilla’s code of conduct enforcement ladder.`}</p>
    <List mdxType="List">
      <Link to=" https://www.contributor-covenant.org/version/2/1/code_of_conduct.html" mdxType="Link">
        <p>{`Version 2.1 of the Contributor Covenant`}</p>
      </Link>
      <Link to="https://www.contributor-covenant.org/faq" mdxType="Link">
        <p>{`Answers to common questions about this code of conduct`}</p>
      </Link>
      <Link to="https://www.contributor-covenant.org/translations" mdxType="Link">
        <p>{`Translations`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      